import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Competences = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "competences section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "competences-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Wat kan ik voor u doen?",
    paragraph:
      "Ik heb veel ervaring opgebouwd in veel verschillende richtingen. Graag geef ik hier enkele voorbeelden van projecten waarbij ik betrokken ben geweest.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="competences-item-content">
                  <h3 className="mt-0 mb-12">
                    Back-end en edge-device software voor industriële IoT
                  </h3>
                  <p className="text-sm mb-0">
                    Ik heb meegewerkt aan een industriëel IoT-project op de AWS
                    cloud. Hierbij was ik verantwoordelijk voor het ontwikkelen
                    van edge-device software die de communicatie tussen PLC en
                    cloud-omgeving mogelijk maakt. Ook heb ik een deel van de
                    serverless software ontwikkeld die binnenkomende data
                    verwerkt.
                  </p>
                </div>
                <div className="competences-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="competences-item-name text-color-high">
                    Industrial IoT
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="competences-item-content">
                  <h3 className="mt-0 mb-12">
                    ERP-consolidatie- en migratieproject
                  </h3>
                  <p className="text-sm mb-0">
                    Ik heb een multinational ondersteuning geboden bij het
                    migreren van grote datasets uit legacy ERP-systemen naar een
                    nieuw ERP-pakket. Door hierbij de migratie formeel vast te
                    leggen en op een geautomatiseerde manier te laten verlopen
                    konden we iteratief, in samenwerking met de stakeholders,
                    tot een steeds betere dataset komen.
                  </p>
                </div>
                <div className="competences-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="competences-item-name text-color-high">
                    Migratie van grote datasets
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="competences-item-content">
                  <h3 className="mt-0 mb-12">
                    IoT weegsysteem en cloud-component
                  </h3>
                  <p className="text-sm mb-0">
                    Ik ben zelf een ontwikkeltraject gestart om een slim
                    weegsysteem te ontwikkelen voor de fruitteelt. Hiervoor heb
                    ik zelf een weegcomputer ontwikkeld en alle software
                    hiervoor ontwikkeld, van embedded tot front-end en alles wat
                    daar tussenin zit. Daarnaast heb ik ook de cloud-component
                    van dit systeem ontwikkeld.
                  </p>
                </div>
                <div className="competences-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="competences-item-name text-color-high">
                    Meer-dan-full-stack development
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="competences-item-content">
                  <h3 className="mt-0 mb-12">
                    Ontwikkelen van visualisatiesoftware voor productieplanning
                  </h3>
                  <p className="text-sm mb-0">
                    Ik heb voor een multinational een pakket ontwikkeld dat de
                    planningsgegevens vanuit hun ERP-systeem inzichtelijk maakt
                    op de productievloer. Voor de klant was dit een
                    kosteneffectieve manier om functionaliteit die hun
                    ERP-pakket niet aan kon bieden, toch te kunnen gebruiken.
                  </p>
                </div>
                <div className="competences-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="competences-item-name text-color-high">
                    Productieondersteunende software
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="competences-item-content">
                  <h3 className="mt-0 mb-12">
                    Cloud-portering van legacy-applicatie
                  </h3>
                  <p className="text-sm mb-0">
                    Voor een industriëel bedrijf heb ik onderdeel uitgemaakt van
                    een project om een complexe bestaande tool met 20+ jaar
                    historie te vervangen door een cloud-oplossing. Ik ben
                    hierbij verantwoordelijk geweest voor het accuraat en
                    bewijsbaar porteren van de legacy-code (geschreven in
                    LabVIEW) naar de nieuwe AWS-omgeving.
                  </p>
                </div>
                <div className="competences-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="competences-item-name text-color-high">
                    Porteren van legacy codebases
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Competences.propTypes = propTypes;
Competences.defaultProps = defaultProps;

export default Competences;
