import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHammer, faBinoculars, faGem, faHandsHelping, faSitemap, faComment } from '@fortawesome/free-solid-svg-icons'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const iconProps={
  style:{width:24,height:24, margin:20},
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Hoe kan ik u helpen?',
    paragraph: 'Mijn doel is om u zo goed mogelijk tot dienst te zijn. Hiervoor richt ik mij op de volgende speerpunten:'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faGem} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Focus op waarde
                    </h4>
                  <p className="m-0 text-sm">
                  Ik probeer altijd uit te vinden hoe ik het op de meest effectieve wijze waarde kan toevoegen voor mijn klant.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faBinoculars} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Verder kijken
                    </h4>
                  <p className="m-0 text-sm">
                  Ik vind het belangrijk om de drijfveren en processen van de klant goed te begrijpen. Alleen zo is het mogelijk om geïntegreerde oplossingen te bieden.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faHammer} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    De beste tool
                    </h4>
                  <p className="m-0 text-sm">
                    Ik heb brede ervaring met veel verschillende ontwikkelomgevingen, -talen en platforms. Op deze manier heb ik een diepe gereedschapskist om voor u de beste oplossing te vinden.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faHandsHelping} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Goede relatie
                    </h4>
                  <p className="m-0 text-sm">
                    Ik heb graag een langere relatie met een klant dan één kort project. Op deze manier raak ik beter bekend met de klant en zijn activiteiten en kunnen we sneller tot goede oplossingen komen.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faSitemap} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Elk formaat project
                    </h4>
                  <p className="m-0 text-sm">
                    Ik heb ervaring met projecten die ik samen met een klant uitvoer, waarbij ik de enige ontwikkelaar ben, maar ook met grote projecten met vele ontwikkelaars over de hele wereld.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon 
                      {...iconProps}
                      icon={faComment} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Korte lijntjes
                    </h4>
                  <p className="m-0 text-sm">
                    Ik heb graag regelmatig contact met de klant om zeker te zijn dat het project de goede kant op gaat. Ik werk graag volgens de Agile methode. Ook kan ik één of meerdere dagen per week op locatie werken bij de klant.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;