import React, { useState } from "react";
import classNames from "classnames";
import MailLink from "../../elements/MailLink";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);
  const openInNewTab = (e) => {
    e.preventDefault();
    window.open(e.target.href, "_blank");
  };

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <MailLink user="mail" domain="vanmarten.nl">
            Contact
          </MailLink>
        </li>
        <li>
          <a
            onClick={openInNewTab}
            href="https://www.linkedin.com/in/martenjacobs/"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a onClick={openInNewTab} href="https://github.com/martenjacobs/">
            GitHub
          </a>
        </li>
        <li>
          <a href="#privacy">Privacy</a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
