import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import GA4React from "ga-4-react";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import Modal from "./components/elements/Modal";
import PrivacyStatement from "./components/sections/PrivacyStatement";

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("consent", "default", {
  ad_storage: "denied",
  analytics_storage: "denied",
});
// Initialize Google Analytics
const ga4react = new GA4React(process.env.REACT_APP_GA_CODE, {
  anonymize_ip: true,
});
const ga4 = new Promise((resolve) => {
  ga4react.initialize().then(resolve);
});
const trackPage = async (page) => {
  (await ga4).pageview("path");
  (await ga4).gtag("event", "pageview", page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    //window.location.hash = "";
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Modal
        show={location.hash === "#privacy"}
        style={{ fontSize: "16px" }}
        handleClose={() => (window.location.hash = "")}
      >
        <PrivacyStatement />
      </Modal>

      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
