import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

const propTypes = {
  ...PropTypes.instanceOf(<a />),
  user: PropTypes.string,
  domain: PropTypes.string,
  isRequired: PropTypes.any,
};

const defaultProps = {};
const generateMailTo = (user, domain) => {
  const random = nanoid(6);
  return `mailto:${user}+web-${random}@${domain}`;
};
const MailLink = ({ user, domain, ...props }) => {
  const { children } = props;
  const mailLink = generateMailTo(user, domain);

  return (
    <a href={mailLink} {...props}>
      {children}
    </a>
  );
};

MailLink.propTypes = propTypes;
MailLink.defaultProps = defaultProps;

export default MailLink;
