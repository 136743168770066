import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import { property } from "lodash";

const LOGOS = {
  oneline: {
    corner: {
      img: require("./../../../assets/images/logo-oneline.svg"),
      width: 312.32,
      height: 61.44,
    },
    nocorner: {
      img: require("./../../../assets/images/logo-oneline-nocorner.svg"),
      width: 295,
      height: 60,
    },
  },
  normal: {
    corner: {
      img: require("./../../../assets/images/logo.svg"),
      width: 151,
      height: 95,
    },
    nocorner: {
      img: require("./../../../assets/images/logo-nocorner.svg"),
      width: 151,
      height: 95,
    },
  },
};
const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  const { img, width, height } =
    LOGOS[props.oneline ? "oneline" : "normal"][
      props.nocorner ? "nocorner" : "corner"
    ];
  delete props.oneline;
  delete props.nocorner;
  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={img}
            alt="Design van Marten"
            width={width}
            height={height}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
