import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  //const [videoModalActive, setVideomodalactive] = useState(false);
  //
  //const openModal = (e) => {
  //  e.preventDefault();
  //  setVideomodalactive(true);
  //}
  //
  //const closeModal = (e) => {
  //  e.preventDefault();
  //  setVideomodalactive(false);
  //}

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Focus op het toevoegen van{" "}
              <span className="text-color-primary">waarde</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Ik ben Marten Jacobs. Sinds 2008 ben ik als freelancer actief.
                Ik werk als IT-consultant en product designer.
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="600"
              >
                Mijn doel is om mijn klanten te helpen door breed te kijken en
                zo échte waarde toe te voegen.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="800">
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="#speerpunten"
                  >
                    Speerpunten
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="#diensten">
                    Diensten
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="1000"
          >
            <Image
              className="has-shadow"
              src={require("./../../assets/images/marten.jpg")}
              alt="Marten"
              width={896}
            />
            <div className="hero-overlay">
              <div className="hero-overlay-box">
                <div className="hero-overlay-content">
                  <h2>Over mij</h2>
                  <p>
                    Als geboren technicus ben ik altijd al geïnteresseerd
                    geweest in computers, software en automatisering. Al op mijn
                    dertiende ben ik betrokken geraakt bij mijn eerste
                    IT-project voor een bedrijf. Toen ik een opleiding moest
                    kiezen, wilde ik toch wel graag iets breder kijken dan
                    alleen maar naar software, en ben ik Industrieel Ontwerpen
                    gaan studeren aan de Universiteit Twente. Toch is de IT
                    nooit ver weg geweest. In 2008 ben ik begonnen met mijn
                    bedrijf als bijbaan. Ik heb sinds die tijd in veel
                    verschillende projecten ervaring opgedaan en weet met
                    vrijwel elke taal en ontwikkelomgeving wel raad. Ik heb
                    gewerkt in teams van één tot tientallen ontwikkelaars en
                    zowel kleine als grote aantallen stakeholders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
