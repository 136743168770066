import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
//import FeaturesSplit from "../components/sections/FeaturesSplit";
import Cta from "../components/sections/Cta";
import Competences from "../components/sections/Competences";

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles id="speerpunten" />
      {/*<FeaturesSplit invertMobile topDivider imageFill id="diensten"/>*/}
      <Competences topDivider id="diensten" />
      <Cta id="contact" split />
    </>
  );
};

export default Home;
