import React from "react";
export const PrivacyStatement = () => (
  <>
    <h3>Privacyverklaring</h3>
    <p>
      Design van Marten, gevestigd aan Greftenweg 16, 7671PC Vriezenveen
      (Nederland), is verantwoordelijk voor de verwerking van persoonsgegevens
      zoals weergegeven in deze privacyverklaring.
    </p>
    <p>
      Contactgegevens: <br />
      https://vanmarten.nl <br />
      Greftenweg 16 <br />
      7671PC, Vriezenveen <br />
      Nederland <br />
      0655520221
    </p>
    <p>
      Marten Jacobs is de Functionaris Gegevensbescherming van Design van
      Marten. Hij is te bereiken via mail@vanmarten.nl
    </p>
    <h4>Persoonsgegevens die ik verwerken </h4>
    <p>
      Design van Marten verwerkt uw persoonsgegevens doordat u gebruik maakt van
      mijn diensten en/of omdat u deze zelf aan mij verstrekt.
    </p>
    <p>
      Hieronder vindt u een overzicht van de persoonsgegevens die ik verwerk:
      <ul>
        <li>Gegevens over uw activiteiten op mijn website</li>
        <li>Internetbrowser en apparaat type</li>
      </ul>
    </p>
    <h4>Bijzondere en/of gevoelige persoonsgegevens die ik verwerk</h4>
    <p>
      Mijn website heeft niet de intentie gegevens te verzamelen over
      websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben
      van ouders of voogd. Ik kan echter niet controleren of een bezoeker ouder
      dan 16 is. Ik raad ouders dan ook aan betrokken te zijn bij de online
      activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over
      kinderen verzameld worden zonder ouderlijke toestemming. Als u er van
      overtuigd bent dat ik zonder die toestemming persoonlijke gegevens heb
      verzameld over een minderjarige, neem dan contact met mij op via
      mail@vanmarten.nl, dan verwijder ik deze informatie.
    </p>
    <h4>
      Met welk doel en op basis van welke grondslag ik persoonsgegevens verwerk
    </h4>
    <p>
      Design van Marten verwerkt uw persoonsgegevens voor de volgende doelen:
      <ul>
        <li>
          Design van Marten analyseert uw gedrag op de website om daarmee de
          website te verbeteren en het aanbod van producten en diensten af te
          stemmen op uw voorkeuren.
        </li>
      </ul>
    </p>
    <h4>Hoe lang ik persoonsgegevens bewaar</h4>
    <p>
      Design van Marten bewaart uw persoonsgegevens niet langer dan strikt nodig
      is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Ik
      hanteer de volgende bewaartermijnen voor de volgende (categorieën) van
      persoonsgegevens:
      <ul>
        <li>Verzamelde analysegegevens van uw bezoek: 2 maanden</li>
      </ul>
    </p>
    <h4>Delen van persoonsgegevens met derden</h4>
    <p>
      Design van Marten verstrekt uitsluitend aan derden en alleen als dit nodig
      is voor de uitvoering van mijn overeenkomst met u of om te voldoen aan een
      wettelijke verplichting.
    </p>
    <h4>Cookies, of vergelijkbare technieken, die ik gebruik</h4>
    <p>Design van Marten gebruikt geen cookies of vergelijkbare technieken.</p>
    <h4>Gegevens inzien, aanpassen of verwijderen</h4>
    <p>
      U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
      verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor
      de gegevensverwerking in te trekken of bezwaar te maken tegen de
      verwerking van uw persoonsgegevens door Design van Marten en heeft u het
      recht op gegevensoverdraagbaarheid. Dat betekent dat u bij mij een verzoek
      kunt indienen om de persoonsgegevens die ik van u beschik in een
      computerbestand naar u of een ander, door u genoemde organisatie, te
      sturen.
    </p>
    <p>
      U kunt een verzoek tot inzage, correctie, verwijdering,
      gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van
      uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen
      naar mail@vanmarten.nl.
    </p>
    <p>
      Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vraag
      ik u een kopie van uw identiteitsbeiks met het verzoek mee te sturen. Maak
      in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met
      nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer
      (BSN) zwart. Dit ter bescherming van uw privacy. Ik reageer zo snel
      mogelijk, maar binnen vier weken, op uw verzoek.
    </p>
    <p>
      Design van Marten wil u er tevens op wijzen dat u de mogelijkheid heeft om
      een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
      Persoonsgegevens. Dat kan via de volgende link:
      https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
    </p>
    <h4>Hoe ik persoonsgegevens beveiligen</h4>
    <p>
      Design van Marten neemt de bescherming van uw gegevens serieus en neemt
      passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste
      openbaarmaking en ongeoorloofde wijzigingen tegen te gaan. Als u de indruk
      heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van
      misbruik, neem dan contact op met via mail@vanmarten.nl
    </p>
  </>
);
export default PrivacyStatement;
